import * as React from "react";
import { PHOTOGRAPHY_LINK, PLINK_LINK } from "../config/external";

export const Copyright = () => (
    <div className="copyright">
        {" "}
        <p className="copyright-text">
            Copyright © Te Rūnganga o Ngāti Kuia 2015-2021
        </p>
        <p className="copyright-text">
            <a
                className="copyright-link"
                href={PLINK_LINK}
                target="_blank"
                rel="noreferrer">
                Nā Plink Software tēnei paetukutuku
            </a>{" "}
            |&nbsp;
            <a
                className="copyright-link"
                href={PHOTOGRAPHY_LINK}
                target="_blank"
                rel="noreferrer">
                Nā Melissa Banks Photographer ngā whakaahua
            </a>
        </p>
    </div>
);
