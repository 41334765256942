import { lazy } from "@loadable/component";
import {
    TertiaryEducationGrantTypeData,
    ApiRequest,
    ServerSuspense,
    canUseDom,
} from "@plinknz/tah-website-elements";
import * as React from "react";
import * as SurveyType from "survey-react";
import { Loader } from "../loader";
import { TERTIARY_GRANT_URL } from "../../config/tertiary-education-grant";
import { GrantPayload } from "../../config/tertiary-education-grant/payload";
import { grantApi } from "../../service/tertiary-education-grant/api";
import { createSurveyConfig } from "../../service/tertiary-education-grant/survey-config";
import { initializeAddressFinderWidget } from "../survey-widgets/address-finder-widget";
import { initializeBankAccountWidget } from "../survey-widgets/nz-bank-account-widget";
import { initializeDatepickerWidget } from "../survey-widgets/datepicker-widget";
import { validBankAccount } from "../../config/survey-validation/valid-bankaccount";
import { applySurveyConfig } from "../../utility/apply-survey-config";
import { transformTertiaryEducationGrantData } from "../../utility/transform-tertiary-education-grant-data";

export interface TertiaryEducationGrantProps {
    data: TertiaryEducationGrantTypeData;
    request?: ApiRequest;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SurveyComponent = lazy<any>(async () =>
    import(`survey-react`).then((module) => ({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        default: module.Survey,
    }))
);

let payload: GrantPayload = null;

export const TertiaryEducationGrantForm = ({
    data: { active },
    request = grantApi,
}: TertiaryEducationGrantProps) => {
    const [surveyModel, updateSurvey] = React.useState<
        SurveyType.ReactSurveyModel
    >(null);
    const [loading, updateLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>(null);

    if (!active && canUseDom()) {
        return null;
    }

    const sendDataToServer = async (survey: SurveyType.ReactSurveyModel) => {
        updateLoading(true);

        try {
            payload = transformTertiaryEducationGrantData(survey);
            await request.post(
                TERTIARY_GRANT_URL.tertiaryEducation,
                payload.data
            );
        } catch (sendError) {
            console.error(sendError);

            if (sendError instanceof Error) {
                setError(sendError.message);
            }
        } finally {
            updateLoading(false);
        }
    };

    React.useEffect(() => {
        (async () => {
            if (loading || error || surveyModel) {
                return;
            }

            try {
                updateLoading(true);
                const survey = await import(
                    /* webpackChunkName: "survey-react" */ "survey-react"
                );
                await import(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    /* webpackChunkName: "surveyjs-widgets" */ "surveyjs-widgets" as any
                );

                // TODO: The run time imports above make it difficult to test
                // this without getting not wrapped in act errors
                // Need to figure out how to do this

                applySurveyConfig(survey);
                survey.surveyLocalization.locales.my.completingSurvey =
                    "Tino Pai - Thank you for your application. Please <a class='button secondary' href='https://plink.co.nz/standalone/kuiaUpload.html'>CLICK HERE</a> and upload documentation to support your application.";
                initializeDatepickerWidget(survey);
                initializeAddressFinderWidget(survey);
                initializeBankAccountWidget(survey);

                survey.FunctionFactory.Instance.register(
                    "validBankAccount",
                    validBankAccount,
                    false
                );
                const result = createSurveyConfig();
                const surveyInstance = new survey.Model(result);

                surveyInstance.locale = "my";

                updateSurvey(surveyInstance);
            } catch (fetchError) {
                console.warn("Error: ", error);
                setError(
                    error ||
                        "There was an error trying to retrieve information from the server. Please reload and try again."
                );
            } finally {
                updateLoading(false);
            }
        })();
    }, []);

    return (
        <div
            className="registration-form || constrain-width"
            data-testid="tertiary-education-grant-form">
            {(loading && (
                <div className="splash">
                    <Loader size="large" />
                </div>
            )) ||
                (error && (
                    <div data-testid="error">
                        <p>{error}</p>
                    </div>
                )) || (
                    <ServerSuspense fallback={<i />}>
                        <SurveyComponent
                            model={surveyModel}
                            onComplete={sendDataToServer}
                        />
                    </ServerSuspense>
                )}
        </div>
    );
};
