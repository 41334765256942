import {
    chunk,
    classNames,
    NewsGridTypeData,
    Page,
} from "@plinknz/tah-website-elements";
import { format, parse } from "date-fns";
import * as React from "react";
import { Link } from "react-router-dom";
import { useVisible } from "react-visible-image";
import { generate } from "shortid";
import { Accordion } from "../accordion";

export type NewsGridTypeName =
    | "ComponentContentBlocksNewsGrid"
    | "ComponentContentBlocksPanuiGrid"
    | "ComponentMemberPageGrid";

export interface NewsGridProps {
    data: NewsGridTypeData;
}

const NewsGridItem = ({
    slug,
    title,
    cover_image,
    published_at,
    thumbnail_image,
}: Page) => {
    const image = thumbnail_image || cover_image;
    const item = React.useRef<HTMLAnchorElement>(null);
    const isVisible = useVisible(item);
    const style: React.CSSProperties = {};
    const imageUrl = image?.formats?.small.url || image?.url;
    const hasThumbnailImage = !!imageUrl;
    const itemClass = classNames("news-grid-item", {
        "has-background": hasThumbnailImage,
    });

    if (imageUrl) {
        style.backgroundImage = isVisible ? `url('${imageUrl}')` : "";
    }

    return (
        <Link
            style={style}
            key={generate()}
            className={itemClass}
            ref={item}
            to={slug}>
            <h3 className="news-grid-item-title">
                {title}
                {hasThumbnailImage && <i className="fas fa-chevron-right" />}
            </h3>
            {published_at && (
                <p className="news-grid-item-date">
                    <time>
                        {format(new Date(published_at), "do 'of' MMMM")}
                    </time>
                </p>
            )}
        </Link>
    );
};

const MAX_GRID_AMOUNT = 3;

interface Month {
    date: string;
    pages: Page[];
}

const SHOW_BY_DATE_THRESHOLD = 15;

export const NewsGrid = ({ data }: NewsGridProps) => {
    let pageData = data;
    const dateKeyFormat = "M-d-yyyy";
    const referenceDate = new Date();
    // eslint-disable-next-line no-underscore-dangle
    const isPanui = pageData.__typename === "ComponentContentBlocksPanuiGrid";
    // eslint-disable-next-line no-underscore-dangle
    const isMemberPage = pageData.__typename === "ComponentMemberPageGrid";

    if (isMemberPage) {
        pageData = {
            ...pageData,
            pages: [...pageData.memberPages],
        };
    }

    const formatSlugs = (page: Page): Page =>
        isPanui ? { ...page, slug: `/panui/${page.slug}` } : page;
    const splitByDate =
        pageData.pages.length > SHOW_BY_DATE_THRESHOLD &&
        pageData.pages.every(({ published_at }) => !!published_at);

    const renderRow = (pages: Page[], index: number) => (
        <div key={index} className="news-grid-items">
            {pages.map(NewsGridItem)}
        </div>
    );

    const sortedPages = pageData.pages
        .slice()
        .sort(
            (a, b) =>
                new Date(b.published_at).valueOf() -
                new Date(a.published_at).valueOf()
        );

    const renderByDate = (pages: Page[]) => {
        const months: Month[] = [];
        pages.forEach((page) => {
            const published = new Date(page.published_at).setDate(1); // set all to first of the month
            const key = format(published, dateKeyFormat);
            const currentMonthIndex = months.findIndex(
                ({ date }) => date === key
            );

            if (currentMonthIndex > -1) {
                months[currentMonthIndex].pages.push(page);
            } else {
                months.push({ date: key, pages: [page] });
            }
        });

        const sortedMonths = months.sort(
            (a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf()
        );

        return (
            <>
                {sortedMonths.map(({ date, pages: monthsPages }, index) => (
                    <Accordion
                        header={format(
                            parse(date, dateKeyFormat, referenceDate),
                            "MMMM, yyyy"
                        )}
                        expand={index === 0}
                        itemCount={monthsPages.length}
                        key={date}>
                        {chunk<Page>(
                            monthsPages.map(formatSlugs),
                            MAX_GRID_AMOUNT
                        ).map(renderRow)}
                    </Accordion>
                ))}
            </>
        );
    };

    return (
        <div className="news-grid" data-testid="news-grid">
            <div className="news-grid-wrapper || constrain-width">
                <h3>{pageData.title}</h3>
                {(splitByDate && <>{renderByDate(sortedPages)}</>) ||
                    chunk<Page>(
                        sortedPages.map(formatSlugs),
                        MAX_GRID_AMOUNT
                    ).map(renderRow)}
            </div>
        </div>
    );
};
