import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { classNames } from "@plinknz/tah-website-elements";

interface DatePickerProps {
    onChange?: (value: Date) => void;
    defaultDate?: Date;
    name?: string;
    label?: string;
}

export const DatePicker = ({
    onChange,
    defaultDate = new Date(),
    name,
    label,
}: DatePickerProps) => {
    const [startDate, setStartDate] = useState(defaultDate);

    return (
        <div className="form-group">
            <div className={classNames("datepicker")}>
                <label className="datepicker-label"> {label} </label>
                <ReactDatePicker
                    data-testid="datepicker-input"
                    className="datepicker-input"
                    name={name}
                    selected={startDate}
                    onChange={(date: Date | null) => {
                        setStartDate(date);
                        onChange(date);
                    }}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    withPortal
                    dateFormat="dd-MMM-yyyy"
                />
            </div>
        </div>
    );
};
